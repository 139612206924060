/**
 * Парсит query параметры из адреса и возвращает значение указанного параметра.
 * @param url Адрес с query параметрами
 * @param paramName Название искомого параметра
 * @returns Значение query параметра или null, если параметр не найден
 */
export function parseQueryParam(url: string, paramName: string): string | null {
  if (!url || !paramName) return null;

  const searchParams = new URLSearchParams(url.split("?")[1]);
  const paramValue = searchParams.get(paramName);

  return paramValue ? decodeURIComponent(paramValue) : null;
}
